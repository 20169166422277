@media only screen and (max-width: 767px) {
  .welcome-container {
    .ant-form-item-explain-error {
      display: flex;
    }
  }
}

#signup-form {
  width: 82%;
  margin: auto;
}
#signup-form label p{
  font-size: 18px;
  line-height: 28px;
}
#signup-form .ant-input{
  font-size: 18px;
}
.welcome-wrapper .ant-form-item label{
  color: #64748B;
}
.welcome-wrapper .ant-form-item label a{
  color: #0f172a !important;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;